const resourceMidnightBlue = '#1F1440';
const resourceRoyalPurple = '#7348FF';
const resourceCrystalBlue = '#38E4FF';
const resourceRed = '#FF2E57';
const resourceGrey = '#EFEEF1';
const resourceWhite = '#FFFFFF';
const resourceOrange = '#FF7227';

export default {
  text: resourceMidnightBlue,
  background: resourceWhite,
  primary: resourceRoyalPurple,
  secondary: resourceRed,
  sidebar: resourceGrey,
  borderColor: resourceMidnightBlue,
  modes: {
    dark: {
      text: "#fff",
      background: resourceMidnightBlue,
      primary: resourceRed,
      secondary: resourceCrystalBlue,
      sidebar: resourceMidnightBlue,
      borderColor: resourceOrange
    },
  }
};
